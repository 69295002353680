import React from 'react'
import Img from 'gatsby-image'
import ContactOptions from '../components/ContactOptions'
import Grid from '../components/Grid'
import Header from '../components/Header'
import Section from '../styles/Section'
import Wrapper from '../styles/Wrapper'
import Arrow from '../images/Arrow.js'
import { colors } from '../styles/variables'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

const margin = 30
const transition = '.2s cubic-bezier(0, 0, 0, 1)'

const Location = ({ image, name, link, children }) => (
  <a
    css={{
      background: `url(${image.childImageSharp.fluid.src})`,
      backgroundSize: 'cover',
      borderRadius: 3,
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      height: 240,
      justifyContent: 'flex-end',
      margin: `0 ${margin}px ${margin}px 0`,
      overflow: 'hidden',
      padding: 30,
      position: 'relative',
      textDecoration: 'none',
      transition: `transform ${transition}`,
      width: 380,
      ':hover': {
        color: 'white',
        transform: 'scale(1.03)',
        '> div:first-of-type': {
          opacity: 0.8,
        },
        span: {
          color: colors.primary,
          path: {
            fill: colors.primary,
          },
        },
      },
    }}
    href={link}
    target="_blank"
    rel="noopener noreferrer"
  >
    <div
      css={{
        background: 'linear-gradient(transparent, rgba(0, 0, 0, 1))',
        height: '100%',
        left: 0,
        opacity: 0.6,
        position: 'absolute',
        top: 0,
        transition: `opacity ${transition}`,
        width: '100%',
      }}
    />

    <h3
      css={{
        color: 'white',
        marginBottom: 5,
        zIndex: 1,
      }}
    >
      {name}
    </h3>

    <address
      css={{
        lineHeight: '1.75em',
        marginBottom: 5,
        zIndex: 1,
      }}
    >
      {children}
    </address>

    <span
      css={{
        fontFamily: 'Quicksand, sans-serif',
        fontWeight: 800,
        lineHeight: '2em',
        textDecoration: 'none',
        transition: `color ${transition}`,
        zIndex: 1,
        '> svg': {
          marginLeft: 10,
          path: {
            fill: 'white',
            transition: `fill ${transition}`,
          },
        },
      }}
    >
      Bekijk op Google Maps
      <Arrow />
    </span>
  </a>
)

export default ({ data }) => (
  <Layout title="Contact opnemen" content="Wil je een persoonlijk advies op maat over voeding en leefstijl? Neem gerust eens contact met me op om vrijblijvend je situatie door te nemen.">
    <Header />
    <Wrapper contentWidth={840}>
      <Section>
        <h1>Contact</h1>
        <div
          css={{
            margin: '0 auto 30px',
            width: 200,
            '@media(min-width: 500px)': {
              float: 'right',
              margin: '0 0 0 50px',
            },
          }}
        >
          <Img fluid={data.lieke.childImageSharp.fluid} />
        </div>
        <p>
          Wil je een persoonlijk advies op maat over voeding en leefstijl? Neem gerust eens contact met me op om vrijblijvend je situatie door te nemen. Doorgaans ben ik telefonisch goed bereikbaar en stuur ik binnen 24 uur een reactie op je e-mail of voicemail bericht.
        </p>
        <ContactOptions
          label="Stuur mij een e-mail"
          link="mailto:info@friska.diet"
        />
      </Section>

      <Grid>
        <Location
          image={data.boekelo}
          name="Boekelo"
          link="https://www.google.nl/maps/place/Friska+-+Di%C3%ABtistenpraktijk/@52.2048193,6.801849,15.88z/data=!4m8!1m2!2m1!1sFriska!3m4!1s0x47b812f4d16eb8c1:0x861abe7cea80f6de!8m2!3d52.2050246!4d6.8017736"
        >
          Boekelose Stoomblekerij 4<br />
          7548 EG Enschede
        </Location>
      </Grid>
    </Wrapper>
  </Layout>
)

export const query = graphql`
  query ContactImages {
    lieke: file(relativePath: { regex: "/lieke/" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    boekelo: file(relativePath: { regex: "/boekelo/" }) {
      childImageSharp {
        fluid(maxWidth: 380) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    enschede: file(relativePath: { regex: "/enschede/" }) {
      childImageSharp {
        fluid(maxWidth: 380) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
