import React from 'react'
import { breakpoints, distances } from '../styles/variables'

export default ({ children }) => (
  <div
    css={{
      display: 'flex',
      flexWrap: 'wrap',
      margin: `0 -${distances.gridMargin.small}px -${
        distances.gridMargin.small
      }px 0`,
      [breakpoints.fullGrid]: {
        margin: `0 -${distances.gridMargin.large}px -${
          distances.gridMargin.large
        }px 0`,
      },
    }}
  >
    {children}
  </div>
)
